<template>
  <div>
    <b-form-select
      v-model="selected"
      v-on:change="updateSelectedPremise($event)"
      :options="options"
      size="md"
    ></b-form-select>
  </div>
</template>

<script>
import { BFormSelect } from "bootstrap-vue";
import store from "@/store/index";
import getCollections from "../../../views/authentication/getCollections";

export default {
  components: {
    BFormSelect,
  },
  data() {
    return {
      selected: [],
      options: [],
    };
  },
  mounted: function () {
    this.getPremises();
  },
  methods: {
    getPremises() {
      const customerDetails = JSON.parse(localStorage.getItem("userData"));
      let arrayLength = customerDetails.AccIds.length;
      const premises = [];
      for (var i = 0; i < arrayLength; i++) {
        premises[i] = {
          value: customerDetails.AccIds[i].MachineID,
          text: customerDetails.AccIds[i].AccName,
        };
      }
      this.selected =
        localStorage.getItem("selectedPremiseID") || premises[0].value;

      this.options = premises;
      return premises;
    },
    updateSelectedPremise(selected) {
      const appLoading = document.getElementById("loading-bg");
      // Show loading screen
      appLoading.style.display = "block";
      fetch(
        "https://prod-09.uksouth.logic.azure.com:443/workflows/56a17d78d83947b5810a929e15f8cec5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=T27RaUcnIpOB8_MYvRHGdfFUKHsiqofmzpAxUz-RYVE",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            caccid: selected,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          store.commit("app/UPDATE_SELECTED_PREMISE_NAME", data.AccName);
          localStorage.setItem("selectedPremiseName", data.AccName);

          store.commit("app/UPDATE_SELECTED_PREMISE_ID", selected);
          localStorage.setItem("selectedPremiseID", selected);

          store.commit("app/UPDATE_SELECTED_PREMISE_HUMAN_ID", data.HumanID);
          localStorage.setItem("selectedPremiseHumanID", data.HumanID);

          let strippedAddress = data.AccAdd1.replace(
            new RegExp("\r\n", "g"),
            ", "
          );

          store.commit("app/UPDATE_SELECTED_PREMISE_ADDRESS", strippedAddress);
          localStorage.setItem("selectedPremiseAddress", strippedAddress);

          store.commit(
            "app/UPDATE_SELECTED_PREMISE_PRODUCT_LIST",
            data.ProductsList
          );
          localStorage.setItem(
            "selectedPremiseProductList",
            JSON.stringify(data.ProductsList)
          );

          const nextCollections = getCollections.nextCollections(
            data.ProductsList
          );

          // Hide Loading Screen
          appLoading.style.display = "none";
        });
    },
  },
};
</script>
