<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-flex justify-content-center justify-content-xl-start"
    >
      <premises-dropdown />
    </div>

    <b-navbar-nav class="nav align-items-center float-right">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/0-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import PremisesDropdown from "./navbar/PremisesDropdown.vue";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown.vue";
import store from "@/store/index";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    PremisesDropdown,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      customerDetails: JSON.parse(localStorage.getItem("customerDetails")),
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Remove selected Premises Info
      localStorage.removeItem("selectedPremiseAddress");
      localStorage.removeItem("selectedPremiseID");
      localStorage.removeItem("selectedPremiseHumanID");
      localStorage.removeItem("selectedPremiseName");
      localStorage.removeItem("selectedPremiseProductList");
      localStorage.removeItem("selectedPremiseNextCollections");
      localStorage.removeItem("selectedPremiseCollections");
      localStorage.removeItem("selectedPremiseNotifications");
      localStorage.removeItem("selectedPremiseNewNotifications");
      localStorage.removeItem("selectedPremiseDocuments");
      localStorage.removeItem("selectedPremiseLastLogin");
      localStorage.removeItem("selectedUserLevels");
      localStorage.removeItem("selectedPremiseHasInvoices");

      store.commit("app/UPDATE_SELECTED_USER_LEVELS", "");
      store.commit("app/UPDATE_SELECTED_PREMISE_NAME", "");
      store.commit("app/UPDATE_SELECTED_PREMISE_ID", "");
      store.commit("app/UPDATE_SELECTED_PREMISE_HUMAN_ID", "");
      store.commit("app/UPDATE_SELECTED_PREMISE_ADDRESS", "");
      store.commit("app/UPDATE_SELECTED_PREMISE_PRODUCT_LIST", []);
      store.commit("app/UPDATE_SELECTED_PREMISE_NEXT_COLLECTIONS", []);
      store.commit("app/UPDATE_SELECTED_PREMISE_COLLECTIONS", []);
      store.commit("app/UPDATE_SELECTED_PREMISE_NOTIFICATIONS", []);
      store.commit("app/UPDATE_SELECTED_PREMISE_NEW_NOTIFICATIONS", []);
      store.commit("app/UPDATE_SELECTED_PREMISE_DOCUMENTS", []);
      store.commit("app/UPDATE_SELECTED_PREMISE_LAST_LOGIN", "");
      store.commit("app/UPDATE_SELECTED_USER_LEVELS", "");
      store.commit("app/UPDATE_SELECTED_PREMISE_HAS_INVOICES", "");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
