<template>
  <layout-vertical>
    <router-view />

    <!-- Using navbar scoped slot -->
    <!-- <div slot="navbar" class="w-100">
      <navbar />
    </div> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import Navbar from "../components/Navbar.vue";

export default {
  components: {
    LayoutVertical,
    Navbar,
  },
};
</script>
